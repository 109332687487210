import lightgrey1 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-01.jpg";
import lightgrey5 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-05.jpg";

// Types d'événements
export type Event = {
  id: number;
  title: string;
  description: string;
  image: string;
  startDate: string; // Format YYYY-MM-DD
  endDate: string; // Format YYYY-MM-DD
  salons: string[];
  promoCode?: string;
};

// Liste des salons disponibles
const SALONS = ["Ville d'Avray", "Villejuif", "Au grey des couleurs"];

// Salons spécifiques pour les promotions
const SALONS_COULEUR = ["Ville d'Avray", "Au grey des couleurs"];
const SALONS_MECHES = ["Villejuif"];

// Liste statique des événements
export const events: Event[] = [
  {
    id: 1,
    title: "MÈCHES & BALAYAGES -50%",
    description:
      "Ce mois-ci, offrez lumière et relief à vos cheveux. -50% sur nos mèches et balayages chez Alexandra Grey Villejuif. Nuances subtiles, résultat lumineux, élégance assurée.",
    image: lightgrey1.src,
    startDate: "2025-04-01",
    endDate: "2025-04-30",
    salons: SALONS_MECHES,
  },
  {
    id: 2,
    title: "COULEUR -50%",
    description:
      "Sublimez votre style avec une couleur sur-mesure. -50% en avril chez Alexandra Grey. Teinte parfaite, éclat maîtrisé, élégance affirmée. L’excellence à prix doux.",
    image: lightgrey5.src,
    startDate: "2025-04-01",
    endDate: "2025-04-30",
    salons: SALONS_COULEUR,
  },
];

// Fonction pour formater la période d'un événement
export function formatEventPeriod(startDate: string, endDate: string): string {
  const debut = new Date(startDate);
  const fin = new Date(endDate);

  const formatDate = (date: Date) => {
    const day = date.getDate();
    const month = date.toLocaleString("fr-FR", { month: "long" });
    return `${day} ${month}`;
  };

  return `${formatDate(debut)} - ${formatDate(fin)}`;
}

// Fonction pour obtenir les événements actuels
export function getCurrentEvents(): Event[] {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return events.filter((event) => {
    // Vérification que la date est valide avant de comparer
    const endDate = new Date(event.endDate);
    if (isNaN(endDate.getTime())) {
      console.warn(
        `Date invalide pour l'événement "${event.title}": ${event.endDate}`,
      );
      return false;
    }

    endDate.setHours(23, 59, 59, 999);
    return endDate >= today;
  });
}
